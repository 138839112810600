import { ADD_SCHEDULE, FETCH_SCHEDULE, DELETE_SCHEDULE, CLEAR_SCHEDULE } from "../actions/types";

const schedules = [];

export default function (state = schedules, action) {
  switch (action.type) {
    case ADD_SCHEDULE:
      return [...state, action.payload];
    case DELETE_SCHEDULE:
      return [...state].filter(item => item.id !== action.payload.id);
    case CLEAR_SCHEDULE:
      return schedules;
    case FETCH_SCHEDULE:
      return [...action.payload];
    default:
      return state;
  }
}
