import { POSTFORM_DATA } from "../actions/types";

const data = {};

export default function (state = data, action) {
    switch (action.type) {
        case POSTFORM_DATA:
            return { ...action.payload, msg: "succesfully posted" };
        default:
            return state;
    }
}