import React, { Component } from 'react'
import Axios from 'axios';
import Timeline from 'react-visjs-timeline';
import "./styles.scss";
import ReactPlayer from 'react-player'
import { Button } from 'reactstrap';
import fullScreen from "../../assets/img/fs.png";
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import ReactGA from 'react-ga';
import {CONFIG} from '../../config';

Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
}

class VideoModule extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            option: {
                width: '100%',
                height: '100px',
                stack: true,
                showMajorLabels: true,
                showCurrentTime: true,
                zoomable: false,
                type: 'background',
                format: {
                    minorLabels: {
                        minute: 'h:mma',
                        hour: 'ha'
                    }
                }
            },
            currentItems: [],
            totalItems: [],
            video: '',
            count: 0,
            videoList: [],
            mute: "Unmute",
            hide: true,
            videoFull: false,
            info:[]
        }
    }



    async componentDidMount() {
        ReactGA.initialize('G-CGWBEN2S28');
        let { data } = await Axios.get(CONFIG.BASE_URL+'/schedules')
        var nowMil = new Date().getTime();
        var startTime = Math.floor(nowMil / 1000 / 60 / 30) * 30 * 60 * 1000;
        var endTime = new Date(startTime).addHours(1).getTime();
        let newData = data.filter((val) => {
            return (val.startDate >= startTime && val.endDate <= endTime)
        })
        let option = this.state.option;

        let videoList = [];
        let currentItems = newData.map((val) => {
            // videoList.push(val.url);
            return {
                start: new Date(val.startDate),
                end: new Date(val.endDate),  // end is optional
                content: val.title,
                url: val.url,
                date: val.startDate
            }
        });
        
        if (currentItems.length == 0) {
            return
        }

        currentItems = currentItems.sort((a, b) => new Date(a.start) - new Date(b.start))
        currentItems.map(val => {
            videoList.push(val.url);
        });
        let info = currentItems;
        option.start = currentItems[0].start;
        option.end = currentItems[currentItems.length - 1].end;
        currentItems = data.map((val) => {
            // videoList.push(val.url);
            return {
                start: new Date(val.startDate),
                end: new Date(val.endDate),  // end is optional
                content: val.title,
                url: val.url,
                date: val.startDate
            }
        });
        
        currentItems = currentItems.sort((a, b) => new Date(a.start) - new Date(b.start))
        // this.ref.current.play();

        this.setState({
            currentItems,
            totalItems: data,
            videoList,
            option,
            info
        });
        var seek = (nowMil - startTime) / 1000;
        this.ref.current.seekTo(Math.round(seek), 'seconds');
        ReactGA.event({
            category: 'Video',
            action: 'Initial Video Play',
          });
        // document.getElementById("video").addEventListener("webkitfullscreenchange", this._handleKeyDown);
        screenfull.on('change', this._handleKeyDown)
    }

    _handleKeyDown = (e) => {
        console.log("bitton")
        
            if(!screenfull.isFullscreen) {
                this.setState({
                    videoFull: false
                });
            }
    }

    handleMute = (e) => {
        if (e.target.id == "fullScreen") {
            this.setState({
                videoFull: true
            });
            screenfull.request(findDOMNode(this.ref.current))
        }
        else {
            this.setState({
                mute: (this.state.mute == "Unmute" ? "Mute" : "Unmute")
            });
        }
    }

    handleEndVideo = () => {
        if (this.state.count == 0) {
            this.setState({
                count: 1
            })
        }
        else {
            window.location.reload()
        //     var nowMil = new Date().getTime();
        //     var startTime = Math.floor(nowMil / 1000 / 60 / 30) * 30 * 60 * 1000;
        //     var endTime = new Date(startTime).addHours(1).getTime();
        //     let newData = this.state.totalItems.filter((val) => {
        //         return (val.startDate >= startTime && val.endDate <= endTime)
        //     })
        //     let option = this.state.option;

        //     let videoList = [];
        //     let currentItems = newData.map((val) => {
        //         videoList.push(val.url);
        //         return {
        //             start: new Date(val.startDate),
        //             end: new Date(val.endDate),  // end is optional
        //             content: val.title,
        //             url: val.url
        //         }
        //     });
        //     currentItems = currentItems.sort((a, b) => new Date(a.start) - new Date(b.start))
        //     currentItems.map(val => {
        //         videoList.push(val.url);
        //     })
        //     let info = currentItems;
        //     option.start = currentItems[0].start;
        //     option.end = currentItems[currentItems.length - 1].end;
        //     currentItems = this.state.totalItems.map((val) => {
        //         // videoList.push(val.url);
        //         return {
        //             start: new Date(val.startDate),
        //             end: new Date(val.endDate),  // end is optional
        //             content: val.title,
        //             url: val.url,
        //             date: val.startDate
        //         }
        //     });
        
        // currentItems = currentItems.sort((a, b) => new Date(a.start) - new Date(b.start))
        //     this.setState({
        //         currentItems,
        //         videoList,
        //         count: 0,
        //         // option: {
        //         //     width: '100%',
        //         //     height: '100px',
        //         //     stack: true,
        //         //     showMajorLabels: true,
        //         //     showCurrentTime: true,
        //         //     zoomable: false,
        //         //     type: 'background',
        //         //     format: {
        //         //         minorLabels: {
        //         //             minute: 'h:mma',
        //         //             hour: 'ha'
        //         //         }
        //         //     }
        //         // }
        //         option,
        //         info
        //     });
        //     var seek = (nowMil - startTime) / 1000;
        //     this.ref.current.seekTo(Math.round(seek), 'seconds');
         }
        ReactGA.event({
            category: 'Video',
            action: 'Video Source Change',
          });
    }

    handleHide = () => {
        this.setState({
            hide: !this.state.hide
        });
    }

    options = {
        weekday: "long", year: "numeric", month: "short",
        day: "numeric", hour: "2-digit", minute: "2-digit"
    };

    render() {
        return (
            <div className="video-module">
                <h1 className="power">Powered By Daily Gospel Network</h1>
                <div className="player-wrap">
                    <Button color="primary" className="mute-button" onClick={this.handleMute}>{this.state.mute}</Button>
                    <img className="full-screen" id="fullScreen" onClick={this.handleMute} src={fullScreen} alt="img" />
                    <ReactPlayer
                        ref={this.ref}
                        url={this.state.videoList[this.state.count]}
                        autoplay
                        playsinline
                        config={{
                            vimeo: {
                                playerOptions: {
                                    url: this.state.videoList[this.state.count],
                                    controls: true,
                                    autoplay: true,
                                    muted: this.state.mute == "Unmute" ? true : false
                                }
                            }
                        }}
                        id="video"
                        playing={true}
                        muted={this.state.mute == "Unmute" ? true : false}
                        onEnded={this.handleEndVideo}
                        width={this.state.videoFull ? "100%" : "100%"} height={this.state.videoFull ? "100%": "500px"} />

                </div>
                <div style={{ position: "relative" }}>
                    {this.state.currentItems.length > 0 && <Timeline
                        options={this.state.option}
                        // clickHandler={clickHandler}
                        // rangechangeHandler={rangeChangeHandler}
                        items={this.state.currentItems}
                        groups={[{
                            id: 1,
                            content: 'Daily Gospel Network',
                        }]}
                    />}
                    {this.state.currentItems.length > 0 && <div className={`info-main ${this.state.hide && "info-d"}`}>
                        <button className={`ShowButton ${this.state.hide && "notShowButton"}`} onClick={this.handleHide}></button>
                        <div className={`inner-info`}>
                            <button className="HideButton" onClick={this.handleHide}>Hide info</button>
                            <h2 className="info-title">{this.state.info[this.state.count].content}</h2>
                            <span className="TimeRange">{this.state.info[this.state.count].start.toLocaleTimeString("en-us", this.options).split(",")[this.state.info[this.state.count].start.toLocaleTimeString("en-us", this.options).split(",").length - 1]} -
                            {this.state.info[this.state.count].end.toLocaleTimeString("en-us", this.options).split(",")[this.state.info[this.state.count].end.toLocaleTimeString("en-us", this.options).split(",").length - 1]}</span>
                            <span className="TimeDuration"> 30min</span>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default VideoModule;