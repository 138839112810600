import React, { Fragment, useEffect } from "react";
import { NavLink } from "reactstrap";
import "../../assets/css/bootstrap.min.css";
import "styles.scss";
import "./styles.scss";
import { withRouter, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../../layout/index";
import { startGetAdminData } from "../../redux/actions/contentAction";
import Video from "../../components/VideoModule";
import bgImage from '../../assets/img/bg.jpg';
import imageText from '../../assets/img/image-text.png';
import bgImage2 from '../../assets/img/bg2.jpg';
import dgLogo from "../../assets/img/dng.png";
import dgiLogo from "../../assets/img/dng-international.png";
import scLogo from "../../assets/img/dng-showcase.png";
import plusLogo from "../../assets/img/dng-plus.png";

function Home({ content, startGetAdminData }) {
  const history = useHistory();
  useEffect(() => {
    startGetAdminData().then(() => { });
  }, []);

  return content.data && content.data[0] ? (
    <Fragment>
      <Layout banner={content.data[0].banner.url || null}>
        <div className="tab-container">
          <img src={bgImage} className="bg" alt="img" />
          <img src={imageText} className="img-text" alt="img" />
          <img src={bgImage2} className="blue-bg" alt="img" />
          <div className="button-container">
            <NavLink href="/" target="_blank">
              <img src={dgLogo} alt="dgn" />
            </NavLink>
            <NavLink href="/dailygospelinternational" target="_blank">
              <img src={dgiLogo} alt="dgni" />
            </NavLink>
            <NavLink href="/dailygospelplus" target="_blank">
              <img src={plusLogo} alt="plusLogo" />
            </NavLink>
            <NavLink href="/showcase" target="_blank">
              <img src={scLogo} alt="scLogo" />
            </NavLink>
          </div>
        </div>
        <Video />
        <div className="body-section">
          {
            content.data[0].bodySection.map((val, ind) => {
              return (
                <Link to={(val.cta ? `/dailygospelinternational${val.cta}` : '') || "/dailygospelinternational"} key={ind}>
                  <img key={ind} src={val.url} alt="body" />
                </Link>
              )
            })
          }
        </div>
        <div className="grid-section">
          {
            content.data[0].footerSection.map((val, ind) => {
              return (
                <Link to={(val.cta ? `/dailygospelinternational${val.cta}` : '') || "/dailygospelinternational"} key={ind}>
                  <img key={ind} src={val.url} alt="body" />
                </Link>
              )
            })
          }
        </div>
      </Layout>
    </Fragment>
  ) : (
    <Fragment>
      Hello
    </Fragment>

  );
}
const TEMP = withRouter(Home);

function mapStateToProps(state) {
  const content = state.content;
  return { content };
}
function mapDispatchToProps(dispatch) {
  return ({
    startGetAdminData: () => dispatch(startGetAdminData()),
  })
}
export default connect(mapStateToProps, mapDispatchToProps)(TEMP);
