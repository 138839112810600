import React, { Fragment, useEffect } from 'react'
import Layout from '../../layout/index';
import { withRouter, Link } from "react-router-dom";
import { startGetAdminData } from "../../redux/actions/contentAction";
import { connect } from 'react-redux';
import "./styles.scss";


const Donate = ({ content, startGetAdminData }) => {
    useEffect(() => {
        if (!connect[0]) {
            startGetAdminData().then(() => {
            });
        }

    }, []);
    return (

        content.data[0] ?
            <Layout banner={content.data[0].banner.url || null}>
                <div className="doneate">
                    <h2 className="text-center">Donate</h2>
                    <div className="give-main">
                        <a className="give" href="https://tithe.ly/give?c=2278330">Give</a>
                    </div>
                </div>
            </Layout> :
            <Fragment />

    )
}

const TEMP = withRouter(Donate);

function mapStateToProps(state) {
    const content = state.content;
    return { content };
}
function mapDispatchToProps(dispatch) {
    return ({
        startGetAdminData: () => dispatch(startGetAdminData()),
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(TEMP);