import React, { Fragment, useState, useEffect } from "react";
import { Table, Row, Col } from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";

import del from "../../assets/img/delete-24px.svg";
import { fetchAllSchedules, deleteSchedule } from "../../api/schedules";
import CustomSpinner from "../CustomSpinner";
import { fetchSchedule, deleteScheduleAction } from "../../redux/actions/scheduleAction";
import { store } from "../../redux/store";
import { connect } from "react-redux";

const fetchEntry = (key, value, index = null) => {
  let response = value;
  switch (key) {
    case "startDate":
    case "endDate":
      response = moment(value).format("DD-MM-YYYY hh:mm:ss A");
      break;
    case "id":
      response = index + 1;
      break;
    default:
      break;
  }
  return response;
};


const Table1 = ({ schedules }) => {
  const [tableData, setTableData] = useState([]);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [errorInProcess, setErrorInProcess] = useState(null);
  const handleDelete = item => _ => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.value) {
        deleteSchedule({ id: item.id, startDate: item.startDate })
          .then(res => {
            store.dispatch(deleteScheduleAction(item));
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
          })
          .catch(err => {
            Swal.fire("Failure!", "Unable to delete.", "error");
          });
      }
    });
  };
  useEffect(() => {
    fetchAllSchedules()
      .then(({ data, status }) => {
        if (status && data && data.length > 0) {
          setTableData(
            [...data].sort((a, b) => {
              if (a.startDate < b.startDate) return -1;
              if (a.startDate > b.startDate) return 1;
              return 0;
            })
          );
          store.dispatch(fetchSchedule([...data]));
        } else setErrorInProcess("No schedules found");
      })
      .catch(err => {
        console.log(err);
        setErrorInProcess("Unable to fetch schedules from DB");
      })
      .finally(_ => setRequestInProcess(false));
  }, []);

  useEffect(() => {
    if ([...schedules].length > 0) {
      setTableData([...schedules].sort((a, b) => {
        if (a.startDate < b.startDate) return -1;
        if (a.startDate > b.startDate) return 1;
        return 0;
      }));
      setRequestInProcess(null);
      setErrorInProcess(null);
    }
  }, [schedules]);

  if (requestInProcess) return <CustomSpinner />;
  if (errorInProcess)
    return (
      <Fragment>
        <Row className="py-5">
          <Col sm={12} className="center">
            <h3>{errorInProcess}</h3>
          </Col>
        </Row>
      </Fragment>
    );

  const styles = {
    maxHeight: "400px",
    overflow: "auto",
    height: "100%",
  };

  if (tableData && tableData && tableData.length > 0)
    return (
      <Fragment>
        <div className="table--container" style={styles}>
          <Table hover>
            <thead>
              <tr>
                {["Sr.No", "Name", "Url", "Start Date", "End Date", "Action"].map((item, index) => (
                  <Fragment key={item + index}>
                    <th className="center">{item}</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) =>
                item && typeof item === "object" && Object.keys(item).length > 0 ? (
                  <Fragment key={index}>
                    <tr className="center">
                      <td>{index + 1}</td>
                      <td>{item.title || "-"}</td>
                      <td>{item.url || "-"}</td>
                      <td>{item.startDate ? fetchEntry("startDate", item.startDate) : "-"}</td>
                      <td>{item.endDate ? fetchEntry("endDate", item.endDate) : "-"}</td>
                      <td onClick={handleDelete(item)} className="pointer">
                        <img src={del} alt="delete" />
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  <Fragment>
                    <h2>No Data</h2>
                  </Fragment>
                )
              )}
            </tbody>
          </Table>
        </div>
      </Fragment>
    );
  else
    return (
      <Row>
        <Col sm={12} className="center py-5">
          <h3>No Records</h3>
        </Col>
      </Row>
    );
};
function mapStateToProps(state) {
  const { schedules = [] } = state;
  return { schedules };
}
export default connect(mapStateToProps, {})(Table1);
