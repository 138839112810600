import axios from "axios";
import {CONFIG} from '../config';

export const addNewSchedule = data => {
  const url = "/schedules";
  return axios({
    url,
    baseURL: CONFIG.BASE_URL,
    method: "POST",
    data: {
      ...data,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const fetchAllSchedules = () => {
  const url = "/schedules";
  return axios({
    url,
    baseURL: CONFIG.BASE_URL,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const deleteSchedule = data => {
  const url = "/schedules";
  return axios({
    url,
    baseURL: CONFIG.BASE_URL,
    method: "DELETE",
    data: {
      ...data,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
};
