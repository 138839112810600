import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import schedulesReducer from "./schedules";
import contentReducers from "./content";
import uploadContent from "./upload";
import formReducer from "./form-reducer";

export default combineReducers({
  auth: authReducer,
  schedules: schedulesReducer,
  content: contentReducers,
  uploadImage: uploadContent,
  formData: formReducer
});
