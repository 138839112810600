import { POSTFORM_DATA } from "./types";
import axios from 'axios';
import {CONFIG} from '../../config';

export const postFormData = (data) => ({
    type: POSTFORM_DATA,
    payload: data
});

export const startPostFormData = (data) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            const url = "/formdata"
            axios({
                url,
                baseURL: CONFIG.BASE_URL_TEMP,
                method: "POST",
                data: {
                    ...data,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(resp => {
                dispatch(postFormData({ ...data, ...resp }))
                resolve();
            }).catch(err => {
                reject(err)
            });
        })
    }
}
