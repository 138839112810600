import React, { Fragment, useState, useEffect } from "react";
import "./styles.scss";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText
} from 'reactstrap';
import classnames from 'classnames';
import { useHistory, Link } from "react-router-dom";
import logo from "../assets/img/daily-gospel-network-logo.png";
import footer_bg from "../assets/img/blue-smoke-bg.jpg";

const Layout = (props) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [navShow, setNavShow] = useState(true);
    const [activeTab, setActiveTab] = useState('2');

    useEffect(() => {
        window.scrollTo(0, 0)
        if (window.location.pathname == '/' || window.location.pathname == '/dailygospelinternational') {
            setNavShow(false);
        }
        else {
            setNavShow(true);
        }

    }, []);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            {navShow && (<Navbar color="light" light expand="md">
                <NavbarBrand href="/dailygospelinternational">
                    Daily Gospel Network TV
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <Link to="/dailygospelinternational">
                                <NavLink>Home</NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/dailygospelinternational/donate">
                                <NavLink>Donate</NavLink>
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>)}
            <div className="banner">
                <img src={props.banner} alt="banner" />
            </div>
            {props.children}
            <footer className="footer" style={{ background: `url(${footer_bg})` }}>
                <div className="footer-logo">
                    <img src={logo} alt="logo" />
                </div>
                <p className="text-center">© 2020 by The Daily Gospel Network | All Rights Reserved | Privacy policy</p>
                <p className="text-center"><a href="mailto:dailygospelnetwork@gmail.com">dailygospelnetwork@gmail.com</a></p>
                <p>The views and opinions expressed on any program are those of the producers and/or the persons appearing on the program and do not necessarily reflect the views and opinions of The Daily Gospel Network, LLC.</p>
            </footer>
        </div>
    )
}

export default Layout;