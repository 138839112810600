import React, { useEffect } from "react";
import {BrowserRouter, Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";

import Home from "./views/Home";
import Donate from "./views/Donate";
import ChurchForm from "./views/ChurchForm";
import PrayerRequest from "./views/PrayerForm";
import ReactGA from 'react-ga';

const HISTORY = createBrowserHistory();

// ReactGA.initialize('UA-187538152');
ReactGA.initialize('G-CGWBEN2S28');
function InternationalApp({ auth }) {
  useEffect(() => {
    // if ('onorientationchange' in window) {
    //   window.addEventListener("orientationchange", function () {
    //     // `this` is now pointing to `window`, not the component. So use `self`.
    //     window.location.reload();
    //   }, false);
    // }
    ReactGA.initialize('G-CGWBEN2S28');
    ReactGA.pageview(window.location.pathname); 
  }, []);
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)} history={HISTORY}>
      <Switch>
        <Route exact path="/dailygospelinternational" component={Home} />
        <Route exact path="/dailygospelinternational/donate" component={Donate} />
        <Route exact path="/dailygospelinternational/let-us-know-about-your-church" component={ChurchForm} />
        <Route exact path="/dailygospelinternational/prayer-request" component={PrayerRequest} />
        {/* <Redirect to="/dailygospelinternational" /> */}
      </Switch>
    </Router>
  );
}

function mapStateToProps(state) {
  const { auth = null } = state;
  return { auth };
}

export default connect(mapStateToProps, {})(InternationalApp);
