import React, { Fragment } from "react";
import { Row, Col, Spinner } from "reactstrap";

const CustomSpinner = () => {
  return (
    <Fragment>
      <Row className="py-5">
        <Col sm={12} className="center">
          <Spinner color="success" style={{ width: "70px", height: "70px" }} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default CustomSpinner;
