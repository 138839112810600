import React, { Fragment, useEffect,useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./styles.scss";
import { bindActionCreators, compose } from 'redux';
import { useParams,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getAlbumItemLists } from "../../../dailygospel/redux/actions/contentAction";
import Header from "../../components/Header";
import logo from "../../assets/img/daily-gospel-network-logo.png";
import footer_bg from "../../assets/img/blue-smoke-bg.jpg";

function Details(props) {
  const history = useHistory();
  const params = useParams();
  const [isLoading,setLoader] = useState(true);
  useEffect(() => {
    props.getAlbumItemLists(params.id).then(()=>{
      setLoader(false);
    });
  }, []);

  const launchPlayer = (e,val) =>{
    if(val && val.uri){
      let id = val.uri.split('/')[2];
      history.push(`/showcase/player/${id}`);
    }
  }

  return (
    <Fragment>
      <Header />
      <div className="heading">{params.title}</div>
      <div className="list-container">
        {
          (props.content.albumItems && props.content.albumItems.length) ?( props.content.albumItems.map((val, ind) => {
            return (
              <div className="card" key={ind} onClick={(e)=>launchPlayer(e,val)}>
                <img key={ind} src={val.pictures.sizes[2].link} alt="img" />
                <div className="title">
                  <span>{val.name}</span>
                </div>
              </div>
            )
          })):null
        }
      </div>
      {isLoading?<div className="loader">Loading...</div>:( (props.content.albumItems && props.content.albumItems.length===0)?<div className="no-data">No Data Available</div>:null)}
      <footer className="footer" style={{ background: `url(${footer_bg})` }}>
        <div className="footer-logo">
          <img src={logo} alt="logo" />
        </div>
        <p className="text-center">© 2020 by The Daily Gospel Network | All Rights Reserved | Privacy policy</p>
        <p className="text-center"><a href="mailto:dailygospelnetwork@gmail.com">dailygospelnetwork@gmail.com</a></p>
        <p>The views and opinions expressed on any program are those of the producers and/or the persons appearing on the program and do not necessarily reflect the views and opinions of The Daily Gospel Network, LLC.</p>
      </footer>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  content: state.content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getAlbumItemLists
  }, dispatch);
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Details);