import React from "react";
import { NavLink } from "reactstrap";
import "../../assets/css/bootstrap.min.css";
import "./index.scss";
import bgImage from '../../assets/img/bg.jpg';
import imageText from '../../assets/img/image-text.png';
import bgImage2 from '../../assets/img/bg2.jpg';
import dgLogo from "../../assets/img/dng.png";
import dgiLogo from "../../assets/img/dng-international.png";
import scLogo from "../../assets/img/dng-showcase.png";
import plusLogo from "../../assets/img/dng-plus.png";

function Header() {
  return  (
    <div className="header-container">
          <img src={bgImage} className="bg" alt="img" />
          <img src={imageText} className="img-text" alt="img" />
          <img src={bgImage2} className="blue-bg" alt="img" />
          <div className="button-container">
            <NavLink href="/" target="_blank">
              <img src={dgLogo} alt="dgn" />
            </NavLink>
            <NavLink href="/dailygospelinternational" target="_blank">
              <img src={dgiLogo} alt="dgni" />
            </NavLink>
            <NavLink href="/dailygospelplus" target="_blank">
              <img src={plusLogo} alt="plusLogo" />
            </NavLink>
            <NavLink href="/showcase" target="_blank">
              <img src={scLogo} alt="scLogo" />
            </NavLink>
          </div>
        </div>
  )
}

export default Header;
