import React, { useEffect } from "react";
import {BrowserRouter, Router, Route, Switch, Redirect,Routes } from "react-router-dom";

import DailygospelApp from './dailygospel/App';
import InternationalApp from './dailygospelinternational/App';
import PlusApp from './dailygospelPlus/App';
import ShowcaseApp from './showcase/App';

function App({ auth }) {
  return (
    <BrowserRouter>
        <Route exact path="/dailygospelinternational" component={InternationalApp} />
        <Route exact path="/dailygospelplus" component={PlusApp} />
        <Route exact path="/showcase" component={ShowcaseApp} />
        <Route exact path="/" component={DailygospelApp} />
    </BrowserRouter>
  );
}

export default App;
