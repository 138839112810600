export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const SIGN_OUT = "SIGN_OUT";
export const CLEAR_AUTH = "CLEAR_AUTH";

export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const CLEAR_SCHEDULE = "CLEAR_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const GETADMIN_DATA = "GETADMIN_DATA";
export const POSTADMIN_DATA = "POSTADMIN_DATA";
export const POSTFORM_DATA = "POSTFORM_DATA";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_ALBUM_DATA = "SET_ALBUM_DATA";
export const SET_ALBUM_ITEM_DATA = "SET_ALBUM_ITEM_DATA";
