import { SIGN_IN, SIGN_OUT, SIGN_UP, CLEAR_AUTH } from "../actions/types";

const initialState = {
  tokens: {},
  userInfo: {},
  userProfile: {},
  isAuthenticated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        tokens: { ...action.payload.tokens },
        userInfo: { ...action.payload.userInfo },
        userProfile: { ...(action.payload.userProfile || { isAdmin: false }) },
        isAuthenticated: action.payload.isAuthenticated,
      };
    case SIGN_OUT:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_UP:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_AUTH:
      return {
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
