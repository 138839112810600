import { GETADMIN_DATA, POSTADMIN_DATA, SET_CATEGORY_DATA, SET_ALBUM_DATA, SET_ALBUM_ITEM_DATA } from "../actions/types";

const data = { categories: [], album: [], albumCount: 0, albumItems: [] };

export default function (state = data, action) {
  switch (action.type) {
    case GETADMIN_DATA:
      return { ...action.payload };
    case POSTADMIN_DATA:
      return { ...action.payload, msg: "succesfully posted" };
    case SET_CATEGORY_DATA:
      return { ...state, categories: action.data.categories };
    case SET_ALBUM_DATA:
      if (state?.album?.length) {
        return { ...state, album: [...state.album, ...action.data.album], albumCount: action.data.albumCount };
      }
      return { ...state, album: action.data.album, albumCount: action.data.albumCount };
    case SET_ALBUM_ITEM_DATA:
      return { ...state, albumItems: action.data.albumItems };
    default:
      return state;
  }
}
