import { UPLOAD_IMAGE } from "../actions/types";

const data = {};

export default function (state = data, action) {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return {...action.payload};
    default:
      return state;
  }
}