import React, { Fragment, useEffect, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./styles.scss";
import { bindActionCreators, compose } from 'redux';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCategoryData, getAlbumData } from "../../../dailygospel/redux/actions/contentAction";
import Header from "../../components/Header";
import logo from "../../assets/img/daily-gospel-network-logo.png";
import footer_bg from "../../assets/img/blue-smoke-bg.jpg";

function Home(props) {
  const history = useHistory();
  const [isLoading, setLoader] = useState(false);
  useEffect(() => {
    if (props.content?.album?.length < props.content?.albumCount || !props.content?.album?.length) {
      setLoader(true);
      props.getCategoryData().then(() => {});
      props.getAlbumData().then(() => {setLoader(false);});;
    }
  }, []);

  window.onscroll = function (ev) {
    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100) && !isLoading) {
      if (props.content?.album?.length < props.content?.albumCount) {
        let count = Math.ceil(props.content.album.length / 25);
        count = count + 1;
        setLoader(true)
        props.getAlbumData(count).then(() => {
          setLoader(false);
        });
      }
    }
  };

  const openDetails = (e, val) => {
    const { name } = val;
    if (val && val.uri) {
      let id = val?.uri?.split('/')[4];
      history.push(`/showcase/list/${id}/${name}`);
    }
  }

  return (
    <Fragment>
      <Header />
      <div className="heading">SHOWCASE</div>
      <div className="list-container">
        {
          props.content.album && props.content.album.length && props.content.album.map((val, ind) => {
            return (
              <div className="card" key={ind} onClick={(e) => openDetails(e, val)}>
                <img key={ind} src={val.pictures.sizes[2].link} alt="img" />
                <div className="title">
                  <span>{val.name}</span>
                </div>
              </div>
            )
          })
        }
      </div>
      {isLoading ? <div className="loader">Loading...</div> : null}
      <footer className="footer" style={{ background: `url(${footer_bg})` }}>
        <div className="footer-logo">
          <img src={logo} alt="logo" />
        </div>
        <p className="text-center">© 2020 by The Daily Gospel Network | All Rights Reserved | Privacy policy</p>
        <p className="text-center"><a href="mailto:dailygospelnetwork@gmail.com">dailygospelnetwork@gmail.com</a></p>
        <p>The views and opinions expressed on any program are those of the producers and/or the persons appearing on the program and do not necessarily reflect the views and opinions of The Daily Gospel Network, LLC.</p>
      </footer>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  content: state.content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCategoryData,
    getAlbumData
  }, dispatch);
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Home);