import { GETADMIN_DATA, POSTADMIN_DATA, SET_CATEGORY_DATA, SET_ALBUM_DATA, SET_ALBUM_ITEM_DATA } from "./types";
import axios from 'axios';
import { CONFIG } from '../../config';

export const postAdminData = (data) => ({
    type: POSTADMIN_DATA,
    payload: data
});

export const startPostAdminData = (id, data) => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            const url = "/content/" + id;
            axios({
                url,
                baseURL: CONFIG.BASE_URL_TEMP,
                method: "PUT",
                data: {
                    ...data,
                },
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(resp => {
                dispatch(postAdminData({ ...data, ...resp }))
                resolve();
            }).catch(err => {
                reject(err)
            });
        })
    }
}

export const getAdminData = (data) => ({
    type: GETADMIN_DATA,
    payload: data
});

export const startGetAdminData = () => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            const url = "/content";
            axios({
                url,
                baseURL: CONFIG.BASE_URL_TEMP,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(resp => {
                dispatch(getAdminData({ ...resp }))
                resolve();
            }).catch(err => {
                reject(err)
            });
        }

        )
    }
}

export const getCategoryData = () => {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            const url = CONFIG.CATEGORY;
            axios({
                url,
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    // 'Access-Control-Allow-Origin':'*'
                },
            }).then(resp => {
                if (resp?.data?.showCases) {
                    dispatch(setCategoryData({ categories: resp?.data?.showCases }));
                } else {
                    dispatch(setCategoryData({ categories: [] }));
                }
                resolve();
            }).catch(err => {
                dispatch(setCategoryData({ categories: [] }));
                resolve();
            });
        });
    }
}

export const setCategoryData = (data) => ({
    type: SET_CATEGORY_DATA,
    data
});

export const getAlbumData = (page) => {
    page = page || '1';
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            const url = `${CONFIG.ALBUM}${page}`;
            axios({
                url,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': "bearer 686b23005cf63e1be8c51b97c5b42321"
                },
            }).then(resp => {
                if (resp?.data?.data) {
                    dispatch(setAlbumData({ album: resp?.data?.data, albumCount: resp?.data?.total }));
                } else {
                    dispatch(setAlbumData({ album: [], albumCount: 0 }));
                }
                resolve();
            }).catch(err => {
                dispatch(setAlbumData({ album: [], albumCount: 0 }));
                resolve();
            });
        });
    }
}

export const setAlbumData = (data) => ({
    type: SET_ALBUM_DATA,
    data
});

export const getAlbumItemLists = (album_id) => {
    let url = `https://api.vimeo.com/users/115195735/albums/${album_id}/videos`;
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            axios({
                url,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': "bearer 686b23005cf63e1be8c51b97c5b42321"
                },
            }).then(resp => {
                if (resp?.data?.data) {
                    dispatch(setAlbumItemData({ albumItems: resp?.data?.data }));
                } else {
                    dispatch(setAlbumItemData({ albumItems: [] }));
                }
                resolve();
            }).catch(err => {
                dispatch(setAlbumItemData({ albumItems: [] }));
                resolve();
            });
        });
    }
}

export const setAlbumItemData = (data) => ({
    type: SET_ALBUM_ITEM_DATA,
    data
});

