import { GETADMIN_DATA, POSTADMIN_DATA} from "./types";
import axios from 'axios';
import {CONFIG} from '../../config';

export const postAdminData = (data) =>({
    type: POSTADMIN_DATA,
    payload: data
});

export const startPostAdminData = (id, data) => {
 return (dispatch) => {
     return new Promise(function(resolve, reject) {
        const url = "/content/"+id;
        axios({
        url,
        baseURL: CONFIG.BASE_URL_TEMP,
        method: "PUT",
        data: {
            ...data,
        },
        headers: {
            "Content-Type": "application/json",
        },
        }).then(resp => {
            dispatch(postAdminData({...data, ...resp}))
            resolve();
        }).catch(err => {
            reject(err)
        });
     })
 }
}

export const getAdminData = (data) =>({
    type: GETADMIN_DATA,
    payload: data
});

export const startGetAdminData = () => {
 return (dispatch) => {
     return new Promise(function(resolve, reject) {
        const url = "/content";
        axios({
        url,
        baseURL: CONFIG.BASE_URL_TEMP,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        }).then(resp => {
            dispatch(getAdminData({...resp}))
            resolve();
        }).catch(err => {
            reject(err)
        });
     }
    
     )}
}



