import { CLEAR_SCHEDULE, FETCH_SCHEDULE, DELETE_SCHEDULE, ADD_SCHEDULE } from "./types";

export const clearSchedule = () => dispatch => {
  dispatch({ type: CLEAR_SCHEDULE });
};
export const fetchSchedule = data => dispatch => {
  dispatch({ type: FETCH_SCHEDULE, payload: [...data] });
};
export const deleteScheduleAction = item => dispatch => {
  dispatch({ type: DELETE_SCHEDULE, payload: { ...item } });
};
export const addSchedule = item => dispatch => {
  dispatch({ type: ADD_SCHEDULE, payload: { ...item } });
};
