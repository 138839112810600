import React, { Component } from 'react'
import "./styles.scss";
import ReactPlayer from 'react-player'
import { Button } from 'reactstrap';
import fullScreen from "../../assets/img/fs.png";
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import ReactGA from 'react-ga';

class VideoModule extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            mute: "Mute",
            videoFull: false
        }
    }

    async componentDidMount() {
        ReactGA.initialize('G-CGWBEN2S28');
        ReactGA.event({
            category: 'Video',
            action: 'Initial Video Play',
        });
    }

    _handleKeyDown = (e) => {
        if (!screenfull.isFullscreen) {
            this.setState({
                videoFull: false
            });
        }
    }

    handleMute = (e) => {
        if (e.target.id == "fullScreen") {
            this.setState({
                videoFull: true
            });
            screenfull.request(findDOMNode(this.ref.current))
        }
        else {
            this.setState({
                mute: (this.state.mute == "Unmute" ? "Mute" : "Unmute")
            });
        }
    }

    handleEndVideo = () => {
        ReactGA.event({
            category: 'Video',
            action: 'Video Source Change',
        });
    }

    render() {
        let videoUrl = this.props.url;
        return (
            <div className="video-module">
                <h1 className="power">Powered By Daily Gospel Network</h1>
                {videoUrl ?
                    (<div className="player-wrap">
                        {/* <Button color="primary" className="mute-button" onClick={this.handleMute}>{this.state.mute}</Button> */}
                        {/* <img className="full-screen" id="fullScreen" onClick={this.handleMute} src={fullScreen} alt="img" /> */}
                        <video
                            ref={this.ref}
                            id="video"
                            autoplay
                            controls={true}
                            autoPlay={true}
                            width={"100%"}
                            height={"100%"}
                            muted={this.state.mute == "Unmute" ? true : false}
                            onEnded={this.handleEndVideo}
                            src={videoUrl}
                        />
                    </div>) : null}
            </div>
        )
    }
}

export default VideoModule;