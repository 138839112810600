import React, { Fragment, useEffect, useState } from 'react'
import Layout from '../../layout/index';
import { withRouter, Link } from "react-router-dom";
import { startGetAdminData } from "../../redux/actions/contentAction";
import { startPostFormData } from "../../redux/actions/form";
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "styles.scss";
import "./styles.scss";


const Prayer = ({ content, startGetAdminData, startPostFormData }) => {

    useEffect(() => {
        if (!connect[0]) {
            startGetAdminData().then(() => {
            });
        }

    }, []);

    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        let formDat = formData;
        formData[e.target.id] = e.target.value;
        setFormData(formDat);
    }

    const validate = () => {
       
        let idArr = ['FirstName','LastName', 'Email', 'PrayerRequest'];
        let error1 = ''
        for(let i = 0; i < idArr.length; i++) {
            if(!formData[idArr[i]]) {
                error1 = error1+ idArr[i] + ", ";
            }
        }
       
        return error1;
    }

    const handleSubmit = () => {
        if(!validate()) {
            setLoader(true);
        
            startPostFormData(formData).then(() => {
                setLoader(false);
                toast.success('Content Saved !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        } else {
            
            toast.error(`Please Fill ${validate()}!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
    }

    return (

        content.data && content.data[0] ?
            <LoadingOverlay
                active={loader}
                spinner
                text='Loading your content...'
                styles={{ height: '100vh' }}
            >
                <Layout banner={content.data[0].banner.url || null}>
                    <div className="doneate">
                        <h2 className="text-center mt-20">Prayer Request</h2>
                        <div className="church-form p-40">
                            <Form onChange={handleChange}>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">First Name *</Label>
                                            <Input type="text" name="firstName" value={formData.firstName} id="FirstName" placeholder="First Name" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Last Name *</Label>
                                            <Input type="text" name="lastName" value={formData.exampleEmail} id="LastName" placeholder="Last Name" />
                                        </FormGroup>
                                    </Col>
                                </Row>
        
                                <Row>
                                    <Col md={12} xs={12}>
                                        <FormGroup>
                                            <Label for="email">Email *</Label>
                                            <Input type="email" name="email" value={formData.email} id="Email" placeholder="Email" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <FormGroup>
                                            <Label for="exampleText">Prayer request *</Label>
                                            <Input type="textarea" name="text" value={formData.description} id="PrayerRequest" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </div>
                    </div>
                </Layout>
                <ToastContainer />
            </LoadingOverlay> :
            <Fragment />

    )
}

const TEMP = withRouter(Prayer);

function mapStateToProps(state) {
    const content = state.content;
    const formData = state.formData;
    return { content, formData };
}
function mapDispatchToProps(dispatch) {
    return ({
        startGetAdminData: () => dispatch(startGetAdminData()),
        startPostFormData: (data) => dispatch(startPostFormData(data))
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(TEMP);