import React, { useEffect } from "react";
import {Router, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";

import Home from "./views/Home";
import Details from "./views/Details";
import Player from "./views/Player";
import ReactGA from 'react-ga';

const HISTORY = createBrowserHistory();
ReactGA.initialize('G-CGWBEN2S28');

function ShowcaseApp({ auth }) {
  useEffect(() => {
    // if ('onorientationchange' in window) {
    //   window.addEventListener("orientationchange", function () {
    //     window.location.reload();
    //   }, false);
    // }
    ReactGA.initialize('G-CGWBEN2S28');
    ReactGA.pageview(window.location.pathname); 
  }, []);
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)} history={HISTORY}>
      <Switch>
        <Route exact path="/showcase/player/:id" component={Player} />
        <Route exact path="/showcase/list/:id/:title" component={Details} />
        <Route exact path="/showcase" component={Home} />
      </Switch>
    </Router>
  );
}

function mapStateToProps(state) {
  const { auth = null } = state;
  return { auth };
}

export default connect(mapStateToProps, {})(ShowcaseApp);
