import React, { Fragment, useEffect, useState } from "react";
import "../../assets/css/bootstrap.min.css";
import "./styles.scss";
import { bindActionCreators, compose } from 'redux';
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getAlbumItemLists } from "../../../dailygospel/redux/actions/contentAction";
import Header from "../../components/Header";
import logo from "../../assets/img/daily-gospel-network-logo.png";
import footer_bg from "../../assets/img/blue-smoke-bg.jpg";
import VideoModule from "../../components/VideoModule";
import axios from "axios";

function Player(props) {
  const params = useParams();
  const [videoUrl, setVideoUrl] = useState('');
  let template = '';
  useEffect(() => {
    axios({
      url: `https://api.vimeo.com/videos/${params.id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': "bearer 686b23005cf63e1be8c51b97c5b42321"
      },
    }).then(resp => {
      if (resp && resp.data) {
        setVideoUrl(resp.data.player_embed_url);
      }
    }).catch(err => {

    });
  }, []);

  return (
    <Fragment>
      <Header />
      <div className="player-container">
        <iframe src={videoUrl} frameBorder="0" width="100%" height="100%" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
      </div>
      <footer className="footer" style={{ background: `url(${footer_bg})` }}>
        <div className="footer-logo">
          <img src={logo} alt="logo" />
        </div>
        <p className="text-center">© 2020 by The Daily Gospel Network | All Rights Reserved | Privacy policy</p>
        <p className="text-center"><a href="mailto:dailygospelnetwork@gmail.com">dailygospelnetwork@gmail.com</a></p>
        <p>The views and opinions expressed on any program are those of the producers and/or the persons appearing on the program and do not necessarily reflect the views and opinions of The Daily Gospel Network, LLC.</p>
      </footer>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  content: state.content
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getAlbumItemLists
  }, dispatch);
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Player);